<template>
	<div class="g-page flex-column">
		<div style="position: fixed; top: -100px;">
			<input type="text" name="username">
			<input type="password" name="password">
		</div>
		
		<div class="g-head flex">
			<div class="logo flex-center-cross">
				<img :src="systemConfig.system_index_logo" alt="">
				<img src="@/assets/image/title.png" alt="">
			</div>
			<div class="flex-grow flex-between-center">
<!--				<div class="search flex-center">
					<i class="icon tf-search"></i>
					<input v-model="searchKeyword" class="flex-grow" type="text" placeholder="快速搜索功能" @focus="searchHandle" @click.stop="showSearchList = searchList.length > 0">
					
					<ul v-show="showSearchList">
						<li v-for="(item, i) in searchList" :key="i" @click="navHandle(item)">
							<p>{{item.menu_name}}</p>
							<p><span><i class="tf tf-address"></i>{{item.menu_path}}</span></p>
						</li>
					</ul>
				</div>-->
				<div></div>
					
				<div class="flex">
					<div v-for="(item, i) in handleList" :key="i" class="handle flex-center" :title="item.name" @click="item.handle()">
						<i class="tf" :class="[item.icon]"></i>
					</div>
					<div class="user-info flex-center">
						<div class="icon flex-center">
							<img src="@/assets/image/head_icon.jpg" alt="">
						</div>
						<span class="name">{{userInfo.user_realname}}</span>
						<i class="tf tf-tri-down"></i>
						
						<div class="menu">
							<div class="item">
								<a href='https://www.lodop.net/download/CLodop_Setup_for_Win64NT_6.580EN.zip' target='_self' style='color: #1492FF;'>打印控件下载</a>
							</div>
							<div class="item" @click="$refs.resetPsw.open()">修改密码</div>
							<div class="item" @click="logout">安全退出</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="flex-grow flex">
			<div class="g-aside">
				<dl v-for="(menu, i) in navList" :key="i" class="item">
					<dt class="flex-center-cross" :class="{'link': menu.menu_unique_key}" @click="navHandle(menu)">
						<i class="icon tf" :class="`tf-nav-${menu.menu_unique_key}`"></i>
						<span class="flex-grow">{{menu.menu_name}}</span>
						<i v-if="menu.child_menu_list" class="tf-angle-down"></i>
					</dt>
					<dd v-if="menu.child_menu_list" class="flex-wrap">
						<a v-for="(item, j) in menu.child_menu_list" :key="j" @click="navHandle(item)">{{item.menu_name}}</a>
					</dd>
				</dl>
			</div>
			
			<div class="flex-grow flex-column">
				<div class="g-tab-list flex">
					<div class="indicator left flex-center" @click="tabMoveHandle(1)"><i class="tf tf-angle-left"></i></div>
					<div class="tab home flex-center" :class="{'z-crt': alivePage == 'home'}" @click="navigateTo({name: 'home'})">
						<i class="tf">&#xe602;</i>
					</div>
					<div ref="tabWrapper" class="list flex-grow">
						<ul ref="tabList" :style="{'left': `${tabListSeat}px`}">
							<li ref="tab" v-for="(item,i) in tabList" :key="i" class="tab" :class="{'z-crt': alivePage == item.key}" @click="navigateTo(item.path || {name: item.key})" @contextmenu.prevent="showRightMenu(item, i)">
								<div class="flex-between-center">
									<span>{{item.name}}</span>
									<a class="tf tf-cross" @click.stop="closePage(item.key)"></a>
								</div>
							</li>
						</ul>
					</div>
					<div class="indicator right flex-center" @click="tabMoveHandle(-1)"><i class="tf tf-angle-right"></i></div>
					<div v-show="rightMenuTab" class="right-menu" :style="{'left': rightMenuPosition}">
						<div v-for="(item, i) in rightMenuList" :key="i" class="item" @click="rightMenuHandle(item.value)">{{item.name}}</div>
					</div>
				</div>
				
				<div class="g-content flex-grow">
					<keep-alive :include="keepAlive">
						<router-view v-if="initialized && (!refreshPage || refreshPage != alivePage)"></router-view>
					</keep-alive>
				</div>
			</div>
		</div>
		
		<c-dialog ref="resetPsw" title="修改密码" width="480" @resolve="resetPassword" @close="$refs.resetPswForm.clear()">
			<c-form ref="resetPswForm">
				<c-form-item label="旧密码" required>
					<c-input type="password" name="old_pwd"></c-input>
				</c-form-item>
				<c-form-item label="新密码" required>
					<c-input type="password" name="user_pwd"></c-input>
				</c-form-item>
				<c-form-item label="确认密码" required>
					<c-input type="password" name="confirm_pwd"></c-input>
				</c-form-item>
			</c-form>
		</c-dialog>
	</div>
</template>

<script>
	import {cryptoJs, debounce, throttle} from '@deanwu/vue-component-library'
	import {mapState} from 'vuex'
	
	const BEFORE_CLOSE_EVENT = {};
	
	export default {
		name: 'Sys',
		
		data() {
			return {
				initialized: false,
				searchKeyword: '',
				showSearchList: false,
				searchList: [],
				
				tabList: [],
				tabListSeat: 0,
				
				refreshPage: '',
				rightMenuList: [{
					name: '刷新',
					value: 'refresh'
				}, {
					name: '关闭',
					value: 'close'
				}, {
					name: '关闭其它标签',
					value: 'closeOther'
				}, {
					name: '关闭右侧标签',
					value: 'closeRight'
				}, {
					name: '关闭全部',
					value: 'closeAll'
				}],
				rightMenuTab: false,
				rightMenuPosition: 0,
				
				alivePage: this.$route.name,
				
				historyList: [],
			}
		},
		
		computed: {
			...mapState(['systemConfig', 'userInfo', 'navList']),
			
			navLibrary() {
				return this.navList.map(item => {
					let keys = [{
						key: item.menu_unique_key,
						name: item.menu_name
					}];
					!function loop(list) {
						if (!list) return;
						list.forEach(item => {
							if (item.menu_unique_key) {
								keys.push({
									key: item.menu_unique_key,
									name: item.menu_name
								});
							}
							loop(item.child_menu_list);
						});
					}(item.child_menu_list);
					return keys;
				})
			},
			
			handleList() {
				let arr = []
				// 	arr.push({
				// 		name: '前往商城',
				// 		icon: 'tf-mall',
				// 		handle: () => {
				// 		}
				// 	});
				return arr;
			},
			
			keepAlive() {
				return ['home'].concat(Array.from(this.tabList.filter(item => item.key != this.refreshPage), item => {
					return item.key;
				}));
			}
		},
		
		watch: {
			$route(val) {
				if (val.path == '/404') {
					this.alivePage = '';
					return
				};
				
				let index = this.historyList.findIndex(item => item.name == val.name);
				if (index > -1) {
					let item = this.historyList.splice(index, 1)[0];
					item.path = val.fullPath;
					this.historyList.push(item);
				} else {
					this.historyList.push({
						path: val.fullPath,
						name: val.name
					});
				}
				this.updateAlivePage();
			},
			
			searchKeyword() {
				this.searchHandle();
			}
		},
		
		created() {
			Promise.all([
				new Promise(resolve => {
					this.request({
						url: '/product/size_config',
						success: data => {
							this.$store.commit('setSizeConfig', data);
							resolve();
						}
					});
				}),
				new Promise(resolve => {
					this.request({
						url: '/product/spec_config',
						success: data => {
							this.$store.commit('setSpecConfig', data);
							resolve();
						}
					});
				})
			]).then(() => {
				this.initialized = true;
			});
		},
		
		mounted() {
			this.historyList.push({
				path: this.$route.fullPath,
				name: this.$route.name
			});
			this.updateAlivePage();
			
			this._resizeHandler = throttle(() => {
				this.tabMoveHandle();
			}, 200, false)
			
			window.addEventListener('resize', this._resizeHandler);
			window.addEventListener('click', () => {
				this.showSearchList = false;
				this.rightMenuTab = false;
			});
			
			this.$bus.$on('beforeCloseTab', pro => {
				BEFORE_CLOSE_EVENT[this.alivePage] = pro;
			})
		},
		
		beforeDestroy() {
			window.removeEventListener('resize', this._resizeHandler);
		},
		
		methods: {
			navHandle(item) {
				var that = this;
				
				!function loop(item) {
					if (item.menu_unique_key) {
						that.navigateTo({
							name: item.menu_unique_key
						});
					} else if (item.path) {
						that.navigateTo({
							path: item.path
						});
					} else if (item.child_menu_list) {
						loop(item.child_menu_list[0]);
					}
				}(item.child_menu_list ? item.child_menu_list[0] : item)
			},
			
			updateAlivePage(route = this.$route) {
				let page;
				for (let i = 0; i < this.navLibrary.length; i++) {
					let nav = this.navLibrary[i];
					page = nav.find(item => {
						return item.key == route.name;
					});
					if (page) {
						break;						
					}
				}
				
				if (route.name == '404') {
					this.redirectTo('/');
				};
				
				this.alivePage = route.name;
				
				if (!['home', '404'].includes(route.name)) {
					var tabIndex = this.tabList.findIndex(item => item.key == route.name);
					page = page || {
						key: route.name,
						path: route.fullPath,
						name: route.meta.title || route.name
					}
					if (tabIndex == -1) {
						this.tabList.push(page);
					} else {
						this.tabList[tabIndex] = page;
					}
					
					this.tabMoveHandle();
				}
			},
			
			async closePage(keys, back = true) {
				keys = typeof keys == 'string' ? [keys] : keys;
				for (let i = keys.length - 1; i >= 0; i--) {
					let key = keys[i];
					
					let tabIndex = this.tabList.findIndex(item => item.key == key);
					if (BEFORE_CLOSE_EVENT[key]) {
						this.navigateTo(this.tabList[tabIndex].path);
						if (await BEFORE_CLOSE_EVENT[key]() === false) {
							continue;
						};
					}
					this.tabList.splice(tabIndex, 1);
					
					let historyIndex = this.historyList.findIndex(item => item.name == key);
					(historyIndex != -1) && this.historyList.splice(historyIndex, 1);
				}
				if (back && keys.includes(this.$route.name)) {
					if (this.historyList.length) {
						this.navigateTo(this.historyList[this.historyList.length - 1].path);
					} else {
						this.navigateTo('/');
					}
				} else {
					this.tabMoveHandle();
				}
			},
			
			closeAlivePage() {
				this.closePage(this.alivePage, false);
			},
			
			tabMoveHandle(dir) {
				this.$nextTick(() => {
					var wrapper = this.$refs.tabWrapper.offsetWidth,
						list = this.$refs.tabList.offsetWidth,
						left = Math.abs(this.tabListSeat),
						maxLeft = list - wrapper;
						
					if (dir) {
						if (maxLeft > 0) {
							this.tabListSeat += dir * parseInt(wrapper * 0.6);
							this.tabListSeat = this.tabListSeat > 0 ? 0 : this.tabListSeat < -maxLeft ? -maxLeft : this.tabListSeat;
						}
						return;
					}
						
					var $crt = this.$refs.tab && this.$refs.tab.find(item => {
						return item.getAttribute('class').includes('z-crt');
					});
					
					if ($crt && maxLeft > 0) {
						if ($crt.offsetLeft < left) {
							this.tabListSeat = -$crt.offsetLeft;
						} else if ($crt.offsetLeft > wrapper + left - $crt.offsetWidth) {
							this.tabListSeat = wrapper - $crt.offsetWidth - $crt.offsetLeft;
						} else {
							this.tabListSeat = this.tabListSeat < -maxLeft ? maxLeft < 0 ? 0 : -maxLeft : this.tabListSeat;
						}
					} else {
						this.tabListSeat = this.tabListSeat < -maxLeft ? maxLeft < 0 ? 0 : -maxLeft : this.tabListSeat;
					}
				});
			},
			
			showRightMenu(item, index) {
				this.rightMenuTab = item;
				this.rightMenuPosition = this.$refs.tab[index].offsetLeft + this.tabListSeat + 75 + 'px';
			},
			
			rightMenuHandle(type) {
				var keys;
				switch (type) {
				case 'refresh':
					this.refreshPage = this.rightMenuTab.key;
					setTimeout(() => {
						this.refreshPage = '';
					});
					break;
					
				case 'close':
					keys = [this.rightMenuTab.key];
					break;
					
				case 'closeOther':
					keys = Array.from(this.tabList.filter(item => item != this.rightMenuTab), item => item.key);
					break;
					
				case 'closeRight':
					var index = this.tabList.findIndex(item => item == this.rightMenuTab);
					keys = Array.from(this.tabList.filter((item, i) => i > index), item => item.key);
					break;
					
				case 'closeAll':
					keys = Array.from(this.tabList, item => item.key);
				}
				
				keys && keys.length && this.closePage(keys);
			},
			
			searchHandle: debounce(function() {
				this.searchRequest && this.searchRequest.abort();
				
				if (this.searchKeyword) {
					this.searchRequest = this.request({
						url: '/system_menu/user_search',
						data: {
							menu_keyword: this.searchKeyword.trim()
						},
						success: data => {
							this.showSearchList = data.length > 0;
							this.searchList = data;
						}
					});
				} else {
					this.showSearchList = false;
					this.searchList = [];
				}
			}, 200, false),
			
			logout() {
				this.$confirm({
					message: '确定要退出登录吗？',
					resolve: () => {
						this.request({
							url: '/sys_user/logout',
							loading: true,
							success: () => {
								this.$store.commit('setSession', '');
								this.navigateTo('/login');
							}
						});
					}
				});
			},
			
			resetPassword(stop) {
				stop();
				this.$refs.resetPswForm.submit({
					url: '/sys_user/update_pwd',
					rule: {
						old_pwd: '请填写旧密码',
						user_pwd: '请填写新密码',
						confirm_pwd: [{
							type: 'empty',
							message: '请在填写一次新密码'
						}, {
							type: 'function',
							fun: (value, data) => {
								return data.user_pwd == value
							},
							message: '两次输入的新密码不一致'
						}]
					},
					dataFormatter: data => {
						data.old_pwd = cryptoJs.MD5(data.old_pwd).toString();
						data.user_pwd = cryptoJs.MD5(data.user_pwd).toString();
						delete data.confirm_pwd;
						return data;
					},
					success: () => {
						this.$message({
							type: 'success',
							message: '密码修改成功'
						});
						this.$refs.resetPsw.close();
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.g-page {
		height: 100vh;
		
		.g-head {
			height: 60px;
			background: var(--theme-main);
			
			.logo {
				width: 210px;
				margin-right: 15px;
				
				img {
					height: 45px;
				}
			}
			
			.search {
				position: relative;
				width: 360px;
				height: 34px;
				border-radius: 17px;
				margin-left: 25px;
				background: rgba(255,255,255,.2);
				
				.icon {
					margin: 0 8px 0 12px;
					color: #fff;
					opacity: .3;
				}
				
				input {
					height: 100%;
					padding-right: 15px;
					font-size: 13px;
					color: #fff;
				}
				
				ul {
					z-index: 99;
					position: absolute;
					left: 0;
					right: 0;
					top: 100%;
					max-height: 500px;
					border: 1px solid #eee;
					overflow: auto;
					background: #fff;
					box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
					
					li {
						padding: 6px 18px;
						font-size: 13px;
						color: #333;
						line-height: 24px;
						cursor: pointer;
					
						&:hover {
							background: #f5f5f5;
						}
						
						&:nth-child(n+2) {
							border-top: 1px solid #eee;
						}
						
						span {
							color: #999;
							
							.tf {
								margin-right: 5px;
							}
						}
					}
				}
			}
			
			.handle {
				width: 56px;
				height: 60px;
				font-size: 20px;
				color: #fff;
				opacity: .8;
				cursor: pointer;
				
				&:hover {
					opacity: 1;
				}
			}
			
			.user-info {
				z-index: 100;
				position: relative;
				height: 60px;
				padding: 0 20px;
				color: #fff;
				cursor: pointer;
				transition: all .3s ease;
				
				&:hover {
					.menu {
						display: block;
					}
				}
				
				.icon {
					width: 34px;
					height: 34px;
					border-radius: 100%;
					overflow: hidden;
					background: #000;
				}
				
				.name {
					margin: 0 6px 0 10px;
					font-size: 13px;
				}
				
				> .tf {
					font-size: 12px;
				}
				
				.menu {
					display: none;
					z-index: 12;
					position: absolute;
					left: 0;
					right: 0;
					top: 100%;
					padding: 8px 0;
					color: #000;
					background: #fff;
					box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
					
					.item {
						position: relative;
						padding: 0 10px 0 16px;
						line-height: 36px;
						font-size: 13px;
						color: #333;
						
						&:hover {
							background: #F5F5F5;
							theme-color(main);
							
							.theme-list {
								display: block;
							}
						}
						
						.tf {
							font-size: 12px;
							color: #333;
						}
						
						.theme-list {
							display: none;
							position: absolute;
							right: 100%;
							top: 0;
							width: 100px;
							padding: 8px 0;
							background: #fff;
							box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
						}
					}
				}
			}
		}
		
		.g-aside {
			z-index: 100;
			position: relative;
			width: 210px;
			overflow: auto;
			background: #fff;
			
			.item {
				border-bottom: 1px solid #eee;
				padding: 10px 0;
				margin: 0 10px;
				
				dt {
					height: 36px;
					padding: 0 8px;
					
					&.link {
						cursor: pointer;
						
						&:hover {
							color: var(--theme-main);
						}
					}
					
					.icon {
						margin-right: 10px;
						font-size: 15px;
					}
				}
				
				dd {
					padding: 0 8px 8px;
					
					a {
						width: 48%;
						margin-top: 10px;
						line-height: 18px;
						font-size: 12px;
						color: #444;
						
						&:hover {
							color: var(--theme-main);
						}
						
						&:nth-child(even) {
							margin-left: 4%;
						}
					}
				}
			}
		}
		
		.g-tab-list {
			z-index: 99;
			position: relative;
			height: 40px;
			border-top: 1px solid #eee;
			border-left: 1px solid #eee;
			background: #fff;
			box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
			
			.list {
				position: relative;
				overflow: hidden;
				background: #fff;
			
				ul {
					float: left;
					position: relative;
					font-size: 0;
					white-space: nowrap;
					transition: left .2s ease;
					
					li {
						display: inline-block;
						
						div {
							height: 100%;
						}
					}
				}
			}
			
			.tab {
				&.home {
					box-sizing: border-box;
					width: 45px;
					padding: 0 10px;
					font-size: 18px;
					
					&.z-crt {
						color: var(--theme-main);
					}
				}
				
				position: relative;
				height: 40px;
				border-right: 1px solid #ddd;
				padding: 0 10px 0 20px;
				cursor: pointer;
				
				&:hover {
					background: #FAFAFA;
				}
				
				&.z-crt {
					span {
						font-weight: bold;
						color: var(--theme-main);
					}
					
					&:before {
						width: 100%;
						background: var(--theme-lesser);
					}
				}
				
				span {
					margin-right: 20px;
					font-size: 14px;
					color: #444;
				}
				
				a {
					padding: 2px;
					border-radius: 100%;
					font-size: 12px;
					color: #aaa;
					
					&:hover {
						background: #EB0000;
						color: #fff;
					}
				}
			}
			
			.indicator {
				z-index: 1;
				position: relative;
				width: 30px;
				cursor: pointer;
				
				&.left {
					border-right: 1px solid #ddd;
				}
				
				&.right {
					border-left: 1px solid #ddd;
					margin-left: -1px;
				}
				
				&:hover {
					font-weight: bold;
					background: #FAFAFA;
				}
			}
				
			.right-menu {
				box-sizing: border-box;
				z-index: 99;
				position: absolute;
				left: 76px;
				top: 40px;
				width: 160px;
				border: 1px solid #ddd;
				padding: 10px 0;
				font-size: 13px;
				color: #666;
				background: #fff;
				box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
				
				.item {
					padding: 0 20px;
					line-height: 36px;
					cursor: pointer;
					
					&:hover {
						color: var(--theme-lesser);
						background: #F5F5F5;
					}
				}
			}
		}
		
		.g-content {
			overflow: auto;
			background: #F5F4F7;
			
			>>> .c-page {
				padding: 10px 0 0 10px;
			}
		}
	}
</style>
